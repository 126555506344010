import React, { useState, useRef, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FaBars, FaTimes } from "react-icons/fa"
import './navbar.css'
import 'aos/dist/aos.css';

function Navbar() {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const location = useLocation();
	const isHomePage = location.pathname === '/' || location.pathname === '/Services';
	const navigate = useNavigate();
	const handleHomeClick = () => {
		navigate('/');
	}
	const navRef = useRef();

	const [state, setState] = useState(false);
	const [burger, setBurger] = useState(true);

	const handleMenu = () => {
		setBurger(!burger);

		navRef.current.classList.toggle('responsive-nav')

	}

	const changeValueScroll = (e) => {
		if (document.documentElement.scrollTop) {
			setState(true);


		} else {
			setState(false);
		}
	}




	window.addEventListener('scroll', changeValueScroll);
	useEffect(() => {

		if (burger === false) {
			setBurger(!burger);

			navRef.current.classList.toggle('responsive-nav')
		}
	}, [window.location.pathname])



	return (
		<div className={state ? 'header black' : 'header'}>
			<nav className='header-container'>
				<div className="logo" onClick={handleHomeClick}>
					<svg xmlns="http://www.w3.org/2000/svg"
						viewBox={screenWidth <= 530 ? "525 380 500 500" : "425 425 400 400"}  >
						<path fill="#0000" opacity="1" stroke="none"
							d="
M690.000000,1242.000000 
	C460.000000,1242.000000 230.500000,1242.000000 1.000000,1242.000000 
	C1.000000,828.333313 1.000000,414.666656 1.000000,1.000000 
	C414.666656,1.000000 828.333313,1.000000 1242.000000,1.000000 
	C1242.000000,414.666656 1242.000000,828.333313 1242.000000,1242.000000 
	C1058.166626,1242.000000 874.333313,1242.000000 690.000000,1242.000000 
M371.000000,641.572998 
	C372.157257,635.056152 369.361053,630.581055 364.712585,625.992249 
	C332.333832,594.029358 300.260590,561.756897 268.082184,529.590942 
	C266.816132,528.325439 265.476837,527.133240 264.082001,525.822571 
	C263.320740,526.414368 262.764160,526.759460 262.313141,527.210144 
	C235.553818,553.952637 208.781982,580.682861 182.120209,607.522339 
	C180.902771,608.747925 180.130341,610.953857 180.092850,612.723145 
	C179.895111,622.052002 180.387421,631.407959 179.888016,640.714172 
	C179.414948,649.529541 181.121643,656.040649 188.516983,662.592224 
	C206.802048,678.791321 223.709549,696.545410 241.170807,713.674438 
	C241.289734,713.791138 241.428024,713.892334 241.528702,714.022827 
	C243.596054,716.702148 245.443176,716.285706 247.613358,713.945618 
	C252.351807,708.836121 257.274292,703.897339 262.489136,698.509033 
	C266.916077,703.456421 270.935394,707.853088 274.844147,712.345947 
	C278.111877,716.101990 281.506897,718.311646 287.125610,718.187500 
	C308.281189,717.720215 329.453278,717.993103 350.619171,718.013489 
	C352.270660,718.015076 353.921692,718.212036 355.573364,718.311462 
	C360.589478,718.613403 365.605835,718.912109 371.847748,719.285339 
	C370.296570,717.230408 369.737793,716.276489 368.981750,715.519531 
	C341.540619,688.044373 314.033905,660.634521 286.685455,633.067566 
	C283.358429,629.713928 281.741699,631.672485 279.515533,633.999634 
	C267.580505,646.476196 255.583481,658.893372 243.627838,671.310425 
	C233.977524,666.669861 224.738358,662.659668 215.934784,657.851868 
	C209.311661,654.234924 202.658875,652.254333 195.113419,652.939514 
	C191.300247,653.285828 187.429672,653.000000 184.740326,653.000000 
	C211.391037,626.349731 238.188416,599.552734 266.544281,571.197327 
	C301.069641,605.727600 336.155426,640.818481 371.000000,675.668030 
	C371.000000,665.165039 371.000000,653.858154 371.000000,641.572998 
M473.530640,668.319397 
	C479.478088,673.187683 485.425507,678.055908 492.141174,683.553040 
	C498.228790,677.183960 504.065338,671.077515 510.043365,664.823120 
	C510.957794,665.630859 511.721405,666.238708 512.408691,666.923157 
	C517.721375,672.213684 523.053711,677.485352 528.304321,682.837036 
	C530.321411,684.892883 531.903870,686.215759 533.951965,682.715210 
	C535.095764,680.760254 537.348206,679.483643 538.672852,677.600220 
	C553.428955,656.619812 560.704956,633.746826 557.091614,607.788696 
	C553.799622,584.139587 543.636902,564.483215 525.460144,548.898621 
	C507.448914,533.455933 486.650391,525.938416 462.986847,525.986694 
	C435.161835,526.043457 407.336639,526.000000 379.511505,526.000000 
	C377.762390,526.000000 376.013245,526.000000 374.314545,526.000000 
	C374.314545,537.055725 374.314545,547.310059 374.314545,558.000000 
	C376.421387,558.000000 378.218140,558.000000 380.014893,558.000000 
	C407.173553,558.000000 434.332336,557.954895 461.490692,558.045105 
	C465.929810,558.059875 470.441467,558.270569 474.791748,559.078857 
	C491.230103,562.132935 504.371124,570.705139 514.004211,584.254272 
	C523.951111,598.244873 528.082031,613.879028 524.935486,630.973511 
	C522.787659,642.642456 518.400879,653.333374 509.024231,662.457520 
	C507.760559,660.862061 506.748413,659.294067 505.460052,658.001343 
	C488.052917,640.535034 470.559265,623.154480 453.222473,605.618835 
	C450.577637,602.943665 448.912445,602.398743 445.987244,605.378418 
	C431.280884,620.358826 416.302185,635.071899 401.606567,650.062622 
	C400.099640,651.599854 399.132721,654.351624 399.110687,656.551819 
	C398.918915,675.710938 399.000031,694.872742 399.000031,714.033752 
	C399.000031,715.611145 399.000031,717.188538 399.000031,718.672363 
	C419.417603,718.672363 439.002869,718.672363 459.000000,718.672363 
	C459.000000,712.717346 459.723358,706.970581 458.858582,701.473633 
	C456.640686,687.375488 461.443207,676.672180 473.530640,668.319397 
M664.500000,558.000000 
	C672.253784,558.000000 680.007629,558.000000 687.679932,558.000000 
	C687.679932,546.934998 687.679932,536.679749 687.679932,526.410400 
	C656.654602,526.410400 625.951050,526.410400 595.327209,526.410400 
	C595.327209,537.079285 595.327209,547.334900 595.327209,558.000000 
	C602.281555,558.000000 608.915405,558.000000 615.549255,558.000000 
	C622.128723,558.000000 628.708191,558.000000 636.664062,558.000000 
	C631.727783,562.873535 627.718323,566.782837 623.764526,570.747559 
	C618.236023,576.291321 610.635132,580.994141 607.839233,587.692383 
	C605.046570,594.382812 607.032898,603.088135 607.010803,610.910461 
	C606.972229,624.574951 606.907532,638.240662 607.067749,651.903381 
	C607.105774,655.146484 606.178162,657.446228 603.853638,659.747375 
	C585.616211,677.801514 567.506836,695.984985 549.369202,714.139832 
	C548.156067,715.354248 547.035400,716.661011 545.233521,718.617737 
	C547.097473,718.825806 547.858948,718.984741 548.620544,718.985107 
	C576.283020,718.999146 603.945740,719.048645 631.607239,718.886536 
	C633.454590,718.875732 635.699158,717.656677 637.072144,716.303040 
	C651.548523,702.029968 665.810913,687.539429 680.321777,673.301941 
	C683.294922,670.384705 683.328674,668.633240 680.332458,665.705688 
	C667.460876,653.128967 654.737976,640.395996 642.190247,627.496887 
	C640.466919,625.725281 639.170044,622.712402 639.137085,620.254578 
	C638.886536,601.594116 639.000000,582.928711 639.000000,564.264893 
	C639.000000,562.323975 639.000000,560.383118 639.000000,558.000000 
	C647.575378,558.000000 655.537659,558.000000 664.500000,558.000000 
M729.000854,602.706299 
	C729.000854,616.294312 729.000854,629.882324 729.000854,643.965210 
	C736.464172,643.965210 743.489258,644.661987 750.314209,643.790955 
	C759.081787,642.671936 766.368164,638.579956 768.447449,628.938782 
	C769.065308,626.073914 768.961487,623.027832 768.977722,620.063599 
	C769.032776,610.028564 764.820374,603.363281 755.026367,601.157654 
	C747.253845,599.407166 739.032532,599.602600 730.998169,599.155090 
	C730.384155,599.120911 729.672180,600.846252 729.000854,602.706299 
M760.013245,527.170715 
	C754.881287,526.859619 749.750366,526.435852 744.615845,526.386414 
	C744.004944,526.380554 743.205933,528.355957 742.780640,529.517883 
	C740.245667,536.443237 737.720398,543.373840 735.337524,550.352478 
	C733.075073,556.978638 730.991272,563.665771 728.694824,570.739136 
	C733.410583,570.739136 737.496094,570.739136 741.513428,570.739136 
	C744.200806,562.999878 744.200806,562.999878 752.289551,563.000000 
	C753.453674,563.000000 754.632019,563.116943 755.779297,562.977966 
	C758.992676,562.588684 760.614075,563.776367 761.140259,567.137939 
	C761.359253,568.536926 762.898438,570.644775 764.064575,570.808167 
	C767.552551,571.296814 771.152771,570.983826 775.211670,570.983826 
	C770.090759,556.074158 765.253418,541.990051 760.013245,527.170715 
M860.000000,626.649658 
	C859.739746,632.349426 859.821838,638.076538 864.896973,641.910095 
	C870.749023,646.330505 876.787231,645.607727 883.127808,641.684326 
	C882.761719,644.114136 882.420288,646.743835 880.927246,648.156128 
	C879.860168,649.165466 876.388306,649.408325 875.471863,648.499023 
	C871.107056,644.168518 866.099731,646.711853 861.348755,645.979980 
	C860.503174,650.573914 862.731812,653.547241 865.968140,654.794800 
	C869.673889,656.223206 873.881836,656.828552 877.888916,656.906738 
	C890.738647,657.157349 894.000000,653.783997 894.000000,641.097961 
	C894.000000,631.845154 894.000000,622.592285 894.000000,612.917847 
	C889.986389,613.845337 885.945923,610.832275 883.342896,615.379150 
	C878.463989,611.507568 873.029480,610.656860 867.302734,613.348633 
	C862.144897,615.773010 859.805054,620.060425 860.000000,626.649658 
M1064.000000,540.501648 
	C1064.000000,535.084534 1064.000000,529.667419 1064.000000,524.302246 
	C1059.628418,524.302246 1056.040649,524.302246 1052.024658,524.302246 
	C1052.024658,530.144165 1052.024658,535.700562 1052.024658,541.491028 
	C1043.670288,536.732605 1034.952881,539.285645 1031.817627,543.624084 
	C1027.716064,549.299438 1027.901001,562.804626 1032.504028,567.925903 
	C1037.135620,573.078918 1044.372559,573.340576 1050.323853,569.837158 
	C1055.950195,570.445129 1059.707886,570.851196 1064.000000,571.315002 
	C1064.000000,560.735107 1064.000000,551.118225 1064.000000,540.501648 
M816.198730,531.034851 
	C812.922791,531.034851 809.646851,531.034851 805.795166,531.034851 
	C805.795166,534.362061 805.795166,537.075073 805.795166,540.475403 
	C798.968933,538.076111 793.154175,538.345764 788.716370,543.440369 
	C785.982422,537.257935 781.145935,541.245789 777.397339,539.925964 
	C777.397339,550.657227 777.397339,560.699707 777.397339,570.683716 
	C781.391113,570.683716 784.984070,570.683716 789.000000,570.683716 
	C789.000000,567.078918 788.999695,563.778748 789.000061,560.478638 
	C789.000977,550.884705 791.184814,548.825439 800.728943,549.641479 
	C800.909851,547.354614 801.091248,545.061646 801.272644,542.768677 
	C801.459473,542.773010 801.646301,542.777344 801.833069,542.781677 
	C801.965698,544.382202 802.098267,545.982788 802.233215,547.612549 
	C803.478088,547.884705 805.267822,548.077576 805.272339,548.305725 
	C805.418640,555.704163 805.392883,563.105957 805.392883,570.624268 
	C810.273621,570.624268 813.978821,570.624268 817.965332,570.624268 
	C817.965332,563.118835 817.965332,555.985046 817.965332,548.655640 
	C823.622925,548.046082 821.803284,544.057861 821.200623,541.100159 
	C819.687195,539.311768 818.749023,537.377014 818.033264,535.363098 
	C817.559387,534.029968 817.404663,532.583435 816.198730,531.034851 
M856.206970,717.640747 
	C861.951721,719.623291 867.107605,718.577881 871.219604,714.648254 
	C874.662598,719.029419 878.843384,716.163879 882.628845,716.904297 
	C882.628845,706.389343 882.628845,696.346008 882.628845,685.620117 
	C878.491516,686.095215 874.842407,686.514221 870.510864,687.011536 
	C867.287476,686.621948 862.969421,685.295349 858.870422,685.788757 
	C852.329163,686.576294 848.791687,691.543823 848.163757,697.718872 
	C847.402466,705.205200 847.766235,712.744080 856.206970,717.640747 
M964.000000,559.481079 
	C964.000000,553.073425 964.000000,546.665710 964.000000,540.304565 
	C957.589600,539.249939 951.895447,541.264221 946.066833,539.253662 
	C942.482544,538.017212 938.156921,538.521667 934.521118,541.779907 
	C929.304382,546.454773 927.431091,556.414001 931.048218,563.535889 
	C932.601257,566.593689 935.649231,569.420166 938.709717,571.063599 
	C943.592102,573.685547 948.437988,571.735535 952.197815,568.785889 
	C955.859863,572.794922 959.900513,570.377319 964.000000,570.817078 
	C964.000000,567.054688 964.000000,563.763977 964.000000,559.481079 
M901.000000,620.574585 
	C901.000000,628.309570 901.000000,636.044617 901.000000,643.683960 
	C905.403015,643.683960 908.989990,643.683960 913.005676,643.683960 
	C913.005676,638.423035 912.821106,633.460571 913.065613,628.519409 
	C913.252686,624.738586 914.396973,620.815308 919.118164,621.171204 
	C923.337402,621.489441 922.982910,625.357971 922.994812,628.495300 
	C923.014038,633.576721 923.000000,638.658325 923.000000,643.704895 
	C927.362610,643.704895 930.946838,643.704895 934.961426,643.704895 
	C934.961426,636.474243 935.210327,629.518982 934.825745,622.598999 
	C934.692017,620.193298 933.499878,617.433533 931.910156,615.598877 
	C927.066772,610.009399 920.020935,610.440491 912.669373,616.321228 
	C911.294189,612.553345 908.828918,611.980957 901.000000,613.785339 
	C901.000000,615.663086 901.000000,617.630554 901.000000,620.574585 
M1014.387146,539.780090 
	C1002.549805,536.843445 995.586670,540.975037 992.145447,549.079834 
	C988.918762,556.679443 991.853577,565.436157 998.303467,569.677673 
	C1003.905334,573.361511 1016.313354,572.620728 1021.262451,568.152710 
	C1023.721436,565.932739 1024.294067,564.076782 1020.253418,562.490112 
	C1017.272827,561.319763 1015.153870,560.654297 1012.114197,562.796326 
	C1007.819824,565.822510 1003.784973,563.674316 1002.338440,557.866089 
	C1009.857117,557.866089 1017.263000,557.866089 1024.590454,557.866089 
	C1025.575684,548.943054 1022.152527,542.642273 1014.387146,539.780090 
M780.497131,614.858032 
	C774.782166,618.152344 772.041687,625.851929 774.192200,634.342896 
	C775.515747,639.569092 780.832397,644.116699 786.634827,644.966064 
	C796.289001,646.379211 802.318726,644.026123 805.950317,637.183472 
	C802.057190,635.888733 799.049316,632.270264 794.088501,636.133667 
	C789.878174,639.412476 786.029175,636.936890 785.274170,630.974854 
	C792.405334,630.974854 799.479919,630.974854 807.834167,630.974854 
	C806.767944,626.634460 806.581299,622.720947 804.895508,619.623474 
	C800.448181,611.451904 791.697571,609.766479 780.497131,614.858032 
M996.206055,696.585022 
	C994.523560,688.715942 986.901245,684.615173 979.422668,685.042236 
	C970.869629,685.530640 963.989502,691.904114 963.014038,699.914001 
	C962.088867,707.510620 966.422424,714.645630 973.158081,717.139404 
	C982.422546,720.569580 993.279602,717.695374 995.777527,710.624878 
	C995.112549,710.208313 994.485718,709.591614 993.745972,709.390808 
	C990.491028,708.507141 987.826050,705.972534 983.871887,709.662903 
	C980.144897,713.141296 975.509521,709.989380 974.545776,703.722046 
	C982.011047,703.722046 989.401794,703.722046 997.087036,703.722046 
	C996.925781,701.203796 996.799744,699.235535 996.206055,696.585022 
M772.000000,704.663269 
	C772.000000,708.614136 772.000000,712.565002 772.000000,716.627014 
	C775.939575,716.627014 779.309021,716.627014 782.993652,716.627014 
	C782.993652,708.617310 783.334717,700.952271 782.784851,693.351685 
	C782.620850,691.084717 780.417847,688.287170 778.358093,686.963684 
	C772.364624,683.112549 766.762695,685.239197 761.685364,689.434387 
	C760.291626,685.564697 757.574890,684.946167 750.278564,686.753967 
	C750.278564,696.659973 750.278564,706.577637 750.278564,716.629883 
	C753.935608,716.629883 757.305969,716.629883 760.480347,716.629883 
	C760.999695,711.336731 761.640015,706.438232 761.910339,701.519470 
	C762.112549,697.842224 763.245605,694.183716 767.086182,694.401001 
	C771.548340,694.653442 772.255371,698.787354 772.002808,702.722778 
	C771.981567,703.054565 772.000000,703.388794 772.000000,704.663269 
M893.947083,557.773926 
	C892.226929,559.506897 890.506775,561.239929 888.786621,562.972900 
	C887.213623,561.263306 885.455261,559.680725 884.155579,557.783875 
	C883.606384,556.982300 884.005005,555.519226 884.003113,554.356873 
	C883.995605,549.746521 884.000000,545.136169 884.000000,540.346191 
	C880.104919,540.346191 876.740234,540.346191 873.000000,540.346191 
	C873.000000,546.144348 872.989929,551.601135 873.002197,557.057983 
	C873.022034,565.896484 876.416626,570.115479 884.895874,571.870056 
	C894.285889,573.813049 903.636230,569.812744 904.567200,562.439148 
	C905.486267,555.159546 904.767944,547.673218 904.767944,540.290283 
	C900.981018,540.290283 897.728760,540.290283 893.991516,540.290283 
	C893.991516,546.014343 893.991516,551.458679 893.947083,557.773926 
M462.216980,578.339172 
	C458.176300,580.394104 455.684052,583.599670 454.462830,588.006470 
	C452.180573,596.242065 456.448669,604.979614 464.263031,607.662170 
	C472.669128,610.547791 481.590393,606.450134 484.811096,598.442139 
	C487.475830,591.816528 485.709045,585.861267 481.305725,581.114380 
	C476.309509,575.728394 469.599976,575.992798 462.216980,578.339172 
M245.386581,616.182739 
	C239.515717,625.601318 245.486343,638.249695 255.598358,640.428955 
	C262.321594,641.877930 270.692474,638.388184 273.905884,631.305603 
	C276.708069,625.129272 274.493591,615.790466 269.236938,612.058289 
	C262.922516,607.575134 252.435593,606.905640 245.386581,616.182739 
M672.708740,649.256836 
	C675.141968,649.504578 677.572937,649.935913 680.008789,649.964966 
	C686.859192,650.046753 691.658264,646.698242 694.010132,640.419250 
	C696.429749,633.959351 694.496399,626.134766 689.454529,621.981812 
	C685.474060,618.703125 675.892761,617.801758 671.569519,620.174866 
	C665.802124,623.340759 662.935181,628.444275 663.137634,634.710022 
	C663.335693,640.839966 665.684998,646.285278 672.708740,649.256836 
M816.786987,634.941162 
	C815.357056,634.130859 813.927185,633.320618 812.174866,632.327576 
	C811.454346,634.698914 810.460144,636.958374 810.189880,639.301453 
	C810.060852,640.419739 811.152283,642.618774 811.955322,642.753479 
	C817.591187,643.699402 823.336426,644.988159 828.967224,644.685852 
	C834.442871,644.391846 836.987854,640.294861 837.000000,634.599243 
	C837.012512,628.742554 834.426758,626.100891 826.912537,624.042969 
	C825.264587,623.591492 823.729736,622.727539 822.142761,622.053589 
	C822.392578,621.283325 822.642456,620.513062 822.892273,619.742737 
	C826.548462,620.691345 830.204651,621.639893 834.381592,622.723511 
	C835.033203,620.037415 835.664612,617.434570 836.550537,613.782593 
	C831.183533,613.130737 826.543274,612.197388 821.885193,612.097961 
	C816.434082,611.981506 811.513611,616.360291 811.011658,620.995728 
	C810.491882,625.795532 813.219238,629.125427 819.226868,631.123901 
	C821.796326,631.978638 824.331665,632.936096 827.145935,633.940918 
	C823.858704,638.117493 820.687744,636.699402 816.786987,634.941162 
M944.923279,700.911255 
	C944.834961,701.052551 944.766052,701.214233 944.649170,701.325562 
	C944.599792,701.372620 944.373413,701.343445 944.355835,701.298584 
	C942.862732,697.492371 941.578064,693.586731 939.808044,689.915039 
	C939.058960,688.361267 937.445190,686.460083 935.954773,686.186584 
	C932.827881,685.612915 929.522400,686.012512 925.601746,686.012512 
	C929.981750,695.879761 934.074219,704.828064 937.900391,713.888794 
	C939.008423,716.512512 940.668579,716.779480 943.179932,717.133240 
	C948.215759,717.842651 950.384216,715.284241 951.925232,711.190735 
	C952.965881,708.426392 954.275391,705.763123 955.468811,703.056519 
	C957.909180,697.522034 960.352661,691.988831 962.900269,686.216309 
	C958.308289,686.216309 954.343262,686.216309 950.527954,686.216309 
	C948.632935,691.036438 946.831116,695.619385 944.923279,700.911255 
M729.197693,716.113831 
	C733.334045,716.490417 737.470459,716.867004 741.718079,717.253662 
	C741.718079,702.011719 741.718079,687.757263 741.718079,673.376831 
	C737.390930,673.376831 733.352478,673.376831 729.036499,673.376831 
	C729.036499,687.563171 729.036499,701.374817 729.197693,716.113831 
M912.000793,527.709106 
	C912.000793,541.984253 912.000793,556.259460 912.000793,570.607605 
	C916.307373,570.607605 920.012756,570.607605 923.694031,570.607605 
	C923.694031,554.959045 923.694031,539.704712 923.694031,524.013428 
	C920.436401,524.013428 917.446594,523.797546 914.522034,524.139465 
	C913.593872,524.247986 912.839478,525.843140 912.000793,527.709106 
M860.674561,548.019653 
	C863.043884,548.750549 865.413208,549.481506 867.820801,550.224243 
	C867.820801,546.926147 867.820801,543.793640 867.820801,540.735229 
	C855.113831,535.645203 846.419495,540.237671 843.808899,553.427063 
	C842.555481,559.759705 844.732422,565.209473 849.728333,569.043335 
	C854.480896,572.690552 860.105835,572.998169 865.685181,570.872498 
	C866.657837,570.501953 867.751465,569.132935 867.876709,568.113037 
	C868.190308,565.559692 867.980225,562.942078 867.980225,560.177124 
	C859.891602,562.739563 857.359375,562.254761 855.583130,558.098816 
	C853.794373,553.913696 855.213867,550.612976 860.674561,548.019653 
M906.970459,693.183105 
	C906.640869,690.097961 908.844971,685.839233 903.170410,685.429138 
	C903.030823,682.595703 902.902954,680.000427 902.774719,677.398193 
	C898.651733,677.398193 894.941956,677.398193 890.977478,677.398193 
	C890.977478,680.268738 890.977478,682.741516 890.977478,685.362671 
	C884.477295,686.236328 887.735107,690.927185 887.292114,692.968445 
	C893.762695,700.546997 891.156616,709.156250 892.273682,716.691528 
	C896.047302,716.691528 899.304199,716.691528 903.000000,716.691528 
	C903.000000,710.932617 903.306396,705.450439 902.888367,700.024109 
	C902.625549,696.612061 903.940186,695.001099 906.970459,693.183105 
M817.438599,717.000000 
	C818.863831,716.891968 820.289001,716.783997 822.093262,716.647217 
	C822.093262,709.335938 822.093262,702.418213 822.093262,695.342163 
	C823.872681,695.078674 825.271729,694.871521 826.767944,694.649963 
	C826.767944,691.743469 826.767944,689.154236 826.767944,686.528076 
	C825.065063,686.098572 823.564148,685.719971 821.684692,685.245850 
	C821.684692,682.408325 821.684692,679.837708 821.684692,677.293030 
	C817.958496,677.293030 814.706787,677.293030 811.221191,677.293030 
	C811.077515,680.164856 810.947571,682.760803 810.810608,685.498596 
	C809.142395,685.892639 807.627075,686.250549 806.333130,686.556213 
	C806.333130,689.499207 806.333130,692.088989 806.333130,694.603455 
	C808.053528,694.963135 809.440430,695.253174 811.193237,695.619690 
	C811.193237,702.764709 811.193237,709.671509 811.193237,717.000000 
	C813.249146,717.000000 814.874573,717.000000 817.438599,717.000000 
M984.000000,560.485718 
	C984.000000,556.529419 984.000000,552.573181 984.000000,548.550781 
	C985.883362,548.098816 987.392090,547.736816 988.817993,547.394653 
	C988.817993,544.933289 988.817993,542.805481 988.817993,540.506348 
	C987.170654,540.122681 985.654602,539.769531 984.161926,539.421875 
	C984.020691,536.535400 983.894104,533.947876 983.769897,531.409790 
	C979.948425,531.409790 976.580139,531.409790 973.163330,531.409790 
	C973.028687,534.421753 972.912415,537.024170 972.799011,539.562988 
	C971.008301,539.918640 969.613953,540.195557 968.280029,540.460510 
	C968.280029,543.040039 968.280029,545.295715 968.280029,547.623169 
	C969.931213,547.910278 971.325195,548.152649 973.255737,548.488281 
	C973.255737,556.084656 973.255737,563.454346 973.255737,570.668335 
	C977.108337,570.668335 980.359314,570.668335 984.000000,570.668335 
	C984.000000,567.384277 984.000000,564.426941 984.000000,560.485718 
M842.000000,698.510742 
	C842.000000,694.426025 842.000000,690.341309 842.000000,686.310242 
	C837.617920,686.310242 834.027222,686.310242 830.394409,686.310242 
	C830.394409,696.661621 830.394409,706.698914 830.394409,716.689331 
	C834.385559,716.689331 837.974854,716.689331 842.000000,716.689331 
	C842.000000,710.760986 842.000000,705.134216 842.000000,698.510742 
M917.464905,686.000000 
	C915.386902,686.000000 913.308899,686.000000 911.329834,686.000000 
	C911.329834,696.758240 911.329834,706.680725 911.329834,716.574036 
	C915.374817,716.574036 919.080933,716.574036 922.660278,716.574036 
	C922.660278,706.222412 922.660278,696.299744 922.660278,686.200562 
	C921.024597,686.123352 919.718079,686.061707 917.464905,686.000000 
M798.298828,686.006165 
	C795.699280,686.006165 793.099731,686.006165 790.306396,686.006165 
	C790.306396,696.556152 790.306396,706.553589 790.306396,716.620605 
	C793.978149,716.620605 797.335144,716.620605 800.985474,716.620605 
	C800.985474,706.988037 801.029480,697.731079 800.909302,688.476257 
	C800.898621,687.656921 799.816772,686.851501 798.298828,686.006165 
M826.000000,554.503418 
	C826.000000,559.925232 826.000000,565.347046 826.000000,570.686035 
	C830.055969,570.686035 833.314270,570.686035 836.602478,570.686035 
	C836.602478,560.329651 836.602478,550.291138 836.602478,540.314514 
	C832.939941,540.314514 829.684692,540.314514 826.000000,540.314514 
	C826.000000,544.915039 826.000000,549.211243 826.000000,554.503418 
M853.000000,629.496582 
	C853.000000,624.074768 853.000000,618.652954 853.000000,613.313965 
	C848.944031,613.313965 845.685730,613.313965 842.397522,613.313965 
	C842.397522,623.670349 842.397522,633.708862 842.397522,643.685486 
	C846.060059,643.685486 849.315308,643.685486 853.000000,643.685486 
	C853.000000,639.084961 853.000000,634.788757 853.000000,629.496582 
M835.993652,532.492798 
	C834.919861,531.018005 833.795227,528.298889 832.783386,528.340210 
	C830.956909,528.414856 828.342957,529.764771 827.673035,531.291077 
	C827.105164,532.584717 828.322815,535.885132 829.622009,536.523438 
	C832.423340,537.899780 835.170532,536.978149 835.993652,532.492798 
M846.816895,601.106506 
	C845.595703,603.156494 844.374512,605.206482 843.153381,607.256470 
	C844.762390,608.166077 846.563599,610.015991 847.929504,609.736755 
	C849.485107,609.418762 851.966431,606.847656 851.707520,605.884277 
	C851.209351,604.030823 849.064148,602.620056 846.816895,601.106506 
M792.269592,675.491699 
	C791.854736,676.728394 790.848816,678.151123 791.165588,679.157471 
	C791.625061,680.617126 792.840332,682.531250 794.100220,682.864624 
	C795.552856,683.248962 798.183960,682.621033 798.881287,681.526611 
	C799.708618,680.228149 799.746094,677.135193 798.813599,676.319092 
	C797.519165,675.186096 795.003906,675.447998 792.269592,675.491699 
M914.839600,675.125488 
	C914.334412,677.308655 913.476196,679.498718 913.518250,681.671265 
	C913.532654,682.416077 916.080627,683.955994 916.977539,683.656189 
	C918.617798,683.107788 920.408447,681.732605 921.208008,680.213501 
	C922.832336,677.127441 920.614197,675.169006 914.839600,675.125488 
M832.125488,676.835876 
	C831.951416,682.462830 834.048340,685.098389 837.297363,683.076355 
	C838.827332,682.124146 840.465271,679.094421 840.014221,677.638000 
	C838.729736,673.491272 835.459229,674.610718 832.125488,676.835876 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M371.000000,642.062134 
	C371.000000,653.858154 371.000000,665.165039 371.000000,675.668030 
	C336.155426,640.818481 301.069641,605.727600 266.544281,571.197327 
	C238.188416,599.552734 211.391037,626.349731 184.740326,653.000000 
	C187.429672,653.000000 191.300247,653.285828 195.113419,652.939514 
	C202.658875,652.254333 209.311661,654.234924 215.934784,657.851868 
	C224.738358,662.659668 233.977524,666.669861 243.627838,671.310425 
	C255.583481,658.893372 267.580505,646.476196 279.515533,633.999634 
	C281.741699,631.672485 283.358429,629.713928 286.685455,633.067566 
	C314.033905,660.634521 341.540619,688.044373 368.981750,715.519531 
	C369.737793,716.276489 370.296570,717.230408 371.847748,719.285339 
	C365.605835,718.912109 360.589478,718.613403 355.573364,718.311462 
	C353.921692,718.212036 352.270660,718.015076 350.619171,718.013489 
	C329.453278,717.993103 308.281189,717.720215 287.125610,718.187500 
	C281.506897,718.311646 278.111877,716.101990 274.844147,712.345947 
	C270.935394,707.853088 266.916077,703.456421 262.489136,698.509033 
	C257.274292,703.897339 252.351807,708.836121 247.613358,713.945618 
	C245.443176,716.285706 243.596054,716.702148 241.528702,714.022827 
	C241.428024,713.892334 241.289734,713.791138 241.170807,713.674438 
	C223.709549,696.545410 206.802048,678.791321 188.516983,662.592224 
	C181.121643,656.040649 179.414948,649.529541 179.888016,640.714172 
	C180.387421,631.407959 179.895111,622.052002 180.092850,612.723145 
	C180.130341,610.953857 180.902771,608.747925 182.120209,607.522339 
	C208.781982,580.682861 235.553818,553.952637 262.313141,527.210144 
	C262.764160,526.759460 263.320740,526.414368 264.082001,525.822571 
	C265.476837,527.133240 266.816132,528.325439 268.082184,529.590942 
	C300.260590,561.756897 332.333832,594.029358 364.712585,625.992249 
	C369.361053,630.581055 372.157257,635.056152 371.000000,642.062134 
M203.801025,662.420227 
	C203.378632,662.390808 202.956238,662.361389 202.533844,662.331970 
	C202.869034,662.736877 203.204224,663.141846 203.831863,664.136414 
	C209.113129,669.149719 214.447418,674.108643 219.660934,679.191467 
	C225.023895,684.419922 230.269073,689.769104 235.565643,695.065613 
	C244.499863,703.999817 244.533264,704.032959 253.249283,695.214294 
	C256.266632,692.161438 259.072266,688.899414 262.148468,685.543213 
	C269.471130,692.903076 276.044495,699.745850 282.932495,706.255737 
	C284.520081,707.756226 287.178162,708.859253 289.357422,708.883118 
	C307.998108,709.086914 326.641937,709.004578 345.284851,708.987732 
	C346.327972,708.986816 347.370941,708.809143 347.303131,708.815308 
	C326.087067,687.598206 304.618286,666.128296 282.977478,644.486450 
	C271.718872,655.756958 259.894043,667.462341 248.258347,679.352783 
	C245.902069,681.760620 244.113129,682.163025 241.013870,680.621887 
	C228.786896,674.542297 216.397095,668.790222 203.801025,662.420227 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M473.236053,668.484985 
	C461.443207,676.672180 456.640686,687.375488 458.858582,701.473633 
	C459.723358,706.970581 459.000000,712.717346 459.000000,718.672363 
	C439.002869,718.672363 419.417603,718.672363 399.000031,718.672363 
	C399.000031,717.188538 399.000031,715.611145 399.000031,714.033752 
	C399.000031,694.872742 398.918915,675.710938 399.110687,656.551819 
	C399.132721,654.351624 400.099640,651.599854 401.606567,650.062622 
	C416.302185,635.071899 431.280884,620.358826 445.987244,605.378418 
	C448.912445,602.398743 450.577637,602.943665 453.222473,605.618835 
	C470.559265,623.154480 488.052917,640.535034 505.460052,658.001343 
	C506.748413,659.294067 507.760559,660.862061 509.024231,662.457520 
	C518.400879,653.333374 522.787659,642.642456 524.935486,630.973511 
	C528.082031,613.879028 523.951111,598.244873 514.004211,584.254272 
	C504.371124,570.705139 491.230103,562.132935 474.791748,559.078857 
	C470.441467,558.270569 465.929810,558.059875 461.490692,558.045105 
	C434.332336,557.954895 407.173553,558.000000 380.014893,558.000000 
	C378.218140,558.000000 376.421387,558.000000 374.314545,558.000000 
	C374.314545,547.310059 374.314545,537.055725 374.314545,526.000000 
	C376.013245,526.000000 377.762390,526.000000 379.511505,526.000000 
	C407.336639,526.000000 435.161835,526.043457 462.986847,525.986694 
	C486.650391,525.938416 507.448914,533.455933 525.460144,548.898621 
	C543.636902,564.483215 553.799622,584.139587 557.091614,607.788696 
	C560.704956,633.746826 553.428955,656.619812 538.672852,677.600220 
	C537.348206,679.483643 535.095764,680.760254 533.951965,682.715210 
	C531.903870,686.215759 530.321411,684.892883 528.304321,682.837036 
	C523.053711,677.485352 517.721375,672.213684 512.408691,666.923157 
	C511.721405,666.238708 510.957794,665.630859 510.043365,664.823120 
	C504.065338,671.077515 498.228790,677.183960 492.141174,683.553040 
	C485.425507,678.055908 479.478088,673.187683 473.236053,668.484985 
M408.000793,706.295044 
	C407.470032,709.591919 409.243774,710.084290 412.058136,710.049316 
	C422.213745,709.922913 432.371887,709.999939 442.529053,710.000000 
	C444.941437,710.000061 447.353790,710.000000 450.000000,710.000000 
	C450.000000,700.196777 449.952759,691.214783 450.044647,682.234192 
	C450.060699,680.665771 450.135071,678.594421 451.076935,677.618774 
	C458.418396,670.014099 465.978699,662.620667 473.328461,655.304321 
	C479.415527,660.463745 485.171753,665.342773 490.448212,669.815186 
	C492.933075,667.628967 495.002380,665.808350 496.518707,664.474304 
	C480.513153,648.457947 464.699951,632.634033 448.774536,616.697876 
	C436.058746,629.427795 422.945312,642.447510 410.026581,655.657532 
	C408.743073,656.969910 408.106903,659.437805 408.086029,661.379272 
	C407.928406,676.030518 408.000000,690.684326 408.000793,706.295044 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M664.000000,558.000000 
	C655.537659,558.000000 647.575378,558.000000 639.000000,558.000000 
	C639.000000,560.383118 639.000000,562.323975 639.000000,564.264893 
	C639.000000,582.928711 638.886536,601.594116 639.137085,620.254578 
	C639.170044,622.712402 640.466919,625.725281 642.190247,627.496887 
	C654.737976,640.395996 667.460876,653.128967 680.332458,665.705688 
	C683.328674,668.633240 683.294922,670.384705 680.321777,673.301941 
	C665.810913,687.539429 651.548523,702.029968 637.072144,716.303040 
	C635.699158,717.656677 633.454590,718.875732 631.607239,718.886536 
	C603.945740,719.048645 576.283020,718.999146 548.620544,718.985107 
	C547.858948,718.984741 547.097473,718.825806 545.233521,718.617737 
	C547.035400,716.661011 548.156067,715.354248 549.369202,714.139832 
	C567.506836,695.984985 585.616211,677.801514 603.853638,659.747375 
	C606.178162,657.446228 607.105774,655.146484 607.067749,651.903381 
	C606.907532,638.240662 606.972229,624.574951 607.010803,610.910461 
	C607.032898,603.088135 605.046570,594.382812 607.839233,587.692383 
	C610.635132,580.994141 618.236023,576.291321 623.764526,570.747559 
	C627.718323,566.782837 631.727783,562.873535 636.664062,558.000000 
	C628.708191,558.000000 622.128723,558.000000 615.549255,558.000000 
	C608.915405,558.000000 602.281555,558.000000 595.327209,558.000000 
	C595.327209,547.334900 595.327209,537.079285 595.327209,526.410400 
	C625.951050,526.410400 656.654602,526.410400 687.679932,526.410400 
	C687.679932,536.679749 687.679932,546.934998 687.679932,558.000000 
	C680.007629,558.000000 672.253784,558.000000 664.000000,558.000000 
M630.000000,599.500244 
	C630.000000,592.579773 630.000000,585.659302 630.000000,577.032837 
	C625.891724,581.329773 623.017029,584.728088 619.726379,587.659485 
	C616.878784,590.196228 615.878967,592.954468 615.914368,596.765930 
	C616.098328,616.584900 616.106506,636.407776 615.864380,656.225525 
	C615.834351,658.683777 614.555359,661.708374 612.832886,663.472412 
	C598.870361,677.771912 584.659424,691.828796 570.539551,705.974976 
	C569.448059,707.068481 568.473206,708.278259 566.940125,710.000000 
	C584.975525,710.000000 602.124207,709.537231 619.229248,710.200867 
	C627.430664,710.519104 633.135376,708.145508 638.661072,702.111633 
	C648.956726,690.869019 660.102234,680.404663 671.102295,669.408264 
	C658.160950,656.527649 645.459106,644.031128 632.994934,631.301941 
	C631.370361,629.642761 630.235352,626.818481 630.133301,624.478943 
	C629.785156,616.499268 630.000000,608.494934 630.000000,599.500244 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M729.003296,602.230042 
	C729.672180,600.846252 730.384155,599.120911 730.998169,599.155090 
	C739.032532,599.602600 747.253845,599.407166 755.026367,601.157654 
	C764.820374,603.363281 769.032776,610.028564 768.977722,620.063599 
	C768.961487,623.027832 769.065308,626.073914 768.447449,628.938782 
	C766.368164,638.579956 759.081787,642.671936 750.314209,643.790955 
	C743.489258,644.661987 736.464172,643.965210 729.000854,643.965210 
	C729.000854,629.882324 729.000854,616.294312 729.003296,602.230042 
M748.715515,632.684875 
	C755.195801,630.314148 757.628418,625.362610 755.910767,618.038757 
	C754.655518,612.686401 749.272400,609.641052 742.280273,610.486938 
	C742.280273,617.825073 742.280273,625.190308 742.280273,632.930054 
	C744.341125,632.930054 746.125244,632.930054 748.715515,632.684875 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M760.214661,527.538330 
	C765.253418,541.990051 770.090759,556.074158 775.211670,570.983826 
	C771.152771,570.983826 767.552551,571.296814 764.064575,570.808167 
	C762.898438,570.644775 761.359253,568.536926 761.140259,567.137939 
	C760.614075,563.776367 758.992676,562.588684 755.779297,562.977966 
	C754.632019,563.116943 753.453674,563.000000 752.289551,563.000000 
	C744.200806,562.999878 744.200806,562.999878 741.513428,570.739136 
	C737.496094,570.739136 733.410583,570.739136 728.694824,570.739136 
	C730.991272,563.665771 733.075073,556.978638 735.337524,550.352478 
	C737.720398,543.373840 740.245667,536.443237 742.780640,529.517883 
	C743.205933,528.355957 744.004944,526.380554 744.615845,526.386414 
	C749.750366,526.435852 754.881287,526.859619 760.214661,527.538330 
M753.587708,542.125549 
	C753.043823,540.850342 752.499878,539.575195 751.575867,537.408813 
	C749.899963,543.111877 748.516357,547.820312 747.046326,552.822876 
	C750.471863,552.822876 753.401611,552.822876 756.519409,552.822876 
	C755.622437,549.393494 754.765015,546.115295 753.587708,542.125549 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M860.000000,626.195435 
	C859.805054,620.060425 862.144897,615.773010 867.302734,613.348633 
	C873.029480,610.656860 878.463989,611.507568 883.342896,615.379150 
	C885.945923,610.832275 889.986389,613.845337 894.000000,612.917847 
	C894.000000,622.592285 894.000000,631.845154 894.000000,641.097961 
	C894.000000,653.783997 890.738647,657.157349 877.888916,656.906738 
	C873.881836,656.828552 869.673889,656.223206 865.968140,654.794800 
	C862.731812,653.547241 860.503174,650.573914 861.348755,645.979980 
	C866.099731,646.711853 871.107056,644.168518 875.471863,648.499023 
	C876.388306,649.408325 879.860168,649.165466 880.927246,648.156128 
	C882.420288,646.743835 882.761719,644.114136 883.127808,641.684326 
	C876.787231,645.607727 870.749023,646.330505 864.896973,641.910095 
	C859.821838,638.076538 859.739746,632.349426 860.000000,626.195435 
M881.327393,634.115356 
	C881.905518,633.326050 882.556519,632.579529 883.049011,631.739929 
	C885.076050,628.284302 884.199768,624.729919 881.169617,623.109070 
	C879.114563,622.009949 875.122253,622.301392 873.211365,623.668884 
	C870.078186,625.911011 869.828552,630.193420 872.706909,632.773804 
	C874.500916,634.382141 877.992188,634.097229 881.327393,634.115356 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M1064.000000,541.001465 
	C1064.000000,551.118225 1064.000000,560.735107 1064.000000,571.315002 
	C1059.707886,570.851196 1055.950195,570.445129 1050.323853,569.837158 
	C1044.372559,573.340576 1037.135620,573.078918 1032.504028,567.925903 
	C1027.901001,562.804626 1027.716064,549.299438 1031.817627,543.624084 
	C1034.952881,539.285645 1043.670288,536.732605 1052.024658,541.491028 
	C1052.024658,535.700562 1052.024658,530.144165 1052.024658,524.302246 
	C1056.040649,524.302246 1059.628418,524.302246 1064.000000,524.302246 
	C1064.000000,529.667419 1064.000000,535.084534 1064.000000,541.001465 
M1048.994507,561.916626 
	C1050.235229,560.468506 1051.936768,559.209839 1052.624878,557.534729 
	C1054.289062,553.483826 1052.154541,549.759338 1047.832520,548.411011 
	C1044.974365,547.519409 1041.088623,550.196655 1040.176270,553.686340 
	C1038.977417,558.271790 1042.338745,561.755920 1048.994507,561.916626 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M816.652893,531.111267 
	C817.404663,532.583435 817.559387,534.029968 818.033264,535.363098 
	C818.749023,537.377014 819.687195,539.311768 821.200623,541.100159 
	C821.803284,544.057861 823.622925,548.046082 817.965332,548.655640 
	C817.965332,555.985046 817.965332,563.118835 817.965332,570.624268 
	C813.978821,570.624268 810.273621,570.624268 805.392883,570.624268 
	C805.392883,563.105957 805.418640,555.704163 805.272339,548.305725 
	C805.267822,548.077576 803.478088,547.884705 802.233215,547.612549 
	C802.098267,545.982788 801.965698,544.382202 801.833069,542.781677 
	C801.646301,542.777344 801.459473,542.773010 801.272644,542.768677 
	C801.091248,545.061646 800.909851,547.354614 800.728943,549.641479 
	C791.184814,548.825439 789.000977,550.884705 789.000061,560.478638 
	C788.999695,563.778748 789.000000,567.078918 789.000000,570.683716 
	C784.984070,570.683716 781.391113,570.683716 777.397339,570.683716 
	C777.397339,560.699707 777.397339,550.657227 777.397339,539.925964 
	C781.145935,541.245789 785.982422,537.257935 788.716370,543.440369 
	C793.154175,538.345764 798.968933,538.076111 805.795166,540.475403 
	C805.795166,537.075073 805.795166,534.362061 805.795166,531.034851 
	C809.646851,531.034851 812.922791,531.034851 816.652893,531.111267 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M855.895020,717.410706 
	C847.766235,712.744080 847.402466,705.205200 848.163757,697.718872 
	C848.791687,691.543823 852.329163,686.576294 858.870422,685.788757 
	C862.969421,685.295349 867.287476,686.621948 870.510864,687.011536 
	C874.842407,686.514221 878.491516,686.095215 882.628845,685.620117 
	C882.628845,696.346008 882.628845,706.389343 882.628845,716.904297 
	C878.843384,716.163879 874.662598,719.029419 871.219604,714.648254 
	C867.107605,718.577881 861.951721,719.623291 855.895020,717.410706 
M860.000122,702.417236 
	C859.664001,706.933655 862.535950,708.959229 866.279907,708.817261 
	C870.111572,708.671997 872.289795,706.228882 872.087708,701.841125 
	C871.909973,697.981140 870.592224,695.374878 866.619751,695.063354 
	C862.148743,694.712769 859.928955,697.099426 860.000122,702.417236 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M964.000000,559.977173 
	C964.000000,563.763977 964.000000,567.054688 964.000000,570.817078 
	C959.900513,570.377319 955.859863,572.794922 952.197815,568.785889 
	C948.437988,571.735535 943.592102,573.685547 938.709717,571.063599 
	C935.649231,569.420166 932.601257,566.593689 931.048218,563.535889 
	C927.431091,556.414001 929.304382,546.454773 934.521118,541.779907 
	C938.156921,538.521667 942.482544,538.017212 946.066833,539.253662 
	C951.895447,541.264221 957.589600,539.249939 964.000000,540.304565 
	C964.000000,546.665710 964.000000,553.073425 964.000000,559.977173 
M953.132324,557.644104 
	C955.300171,553.054993 952.811096,550.394714 949.065674,548.628845 
	C945.850525,547.112915 943.369324,548.412842 941.828247,551.558899 
	C940.061218,555.166077 941.134644,560.526428 944.338074,561.541077 
	C947.673096,562.597473 951.277710,562.673096 953.132324,557.644104 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M901.000000,620.086304 
	C901.000000,617.630554 901.000000,615.663086 901.000000,613.785339 
	C908.828918,611.980957 911.294189,612.553345 912.669373,616.321228 
	C920.020935,610.440491 927.066772,610.009399 931.910156,615.598877 
	C933.499878,617.433533 934.692017,620.193298 934.825745,622.598999 
	C935.210327,629.518982 934.961426,636.474243 934.961426,643.704895 
	C930.946838,643.704895 927.362610,643.704895 923.000000,643.704895 
	C923.000000,638.658325 923.014038,633.576721 922.994812,628.495300 
	C922.982910,625.357971 923.337402,621.489441 919.118164,621.171204 
	C914.396973,620.815308 913.252686,624.738586 913.065613,628.519409 
	C912.821106,633.460571 913.005676,638.423035 913.005676,643.683960 
	C908.989990,643.683960 905.403015,643.683960 901.000000,643.683960 
	C901.000000,636.044617 901.000000,628.309570 901.000000,620.086304 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M1014.790405,539.869751 
	C1022.152527,542.642273 1025.575684,548.943054 1024.590454,557.866089 
	C1017.263000,557.866089 1009.857117,557.866089 1002.338440,557.866089 
	C1003.784973,563.674316 1007.819824,565.822510 1012.114197,562.796326 
	C1015.153870,560.654297 1017.272827,561.319763 1020.253418,562.490112 
	C1024.294067,564.076782 1023.721436,565.932739 1021.262451,568.152710 
	C1016.313354,572.620728 1003.905334,573.361511 998.303467,569.677673 
	C991.853577,565.436157 988.918762,556.679443 992.145447,549.079834 
	C995.586670,540.975037 1002.549805,536.843445 1014.790405,539.869751 
M1005.027527,547.469604 
	C1004.348877,548.423523 1003.670166,549.377441 1002.872498,550.498596 
	C1006.613220,550.498596 1009.704773,550.498596 1013.125916,550.498596 
	C1009.637634,545.621033 1009.637634,545.621033 1005.027527,547.469604 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M780.808594,614.633545 
	C791.697571,609.766479 800.448181,611.451904 804.895508,619.623474 
	C806.581299,622.720947 806.767944,626.634460 807.834167,630.974854 
	C799.479919,630.974854 792.405334,630.974854 785.274170,630.974854 
	C786.029175,636.936890 789.878174,639.412476 794.088501,636.133667 
	C799.049316,632.270264 802.057190,635.888733 805.950317,637.183472 
	C802.318726,644.026123 796.289001,646.379211 786.634827,644.966064 
	C780.832397,644.116699 775.515747,639.569092 774.192200,634.342896 
	C772.041687,625.851929 774.782166,618.152344 780.808594,614.633545 
M793.662537,623.998535 
	C794.208862,623.801758 794.755188,623.604980 795.301453,623.408142 
	C793.612854,622.081726 792.024475,619.905884 790.204651,619.691040 
	C788.679932,619.511047 786.884888,621.620911 785.209473,622.716797 
	C785.474243,623.144531 785.738953,623.572266 786.003662,624.000000 
	C788.294067,624.000000 790.584473,624.000000 793.662537,623.998535 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M996.439880,696.926147 
	C996.799744,699.235535 996.925781,701.203796 997.087036,703.722046 
	C989.401794,703.722046 982.011047,703.722046 974.545776,703.722046 
	C975.509521,709.989380 980.144897,713.141296 983.871887,709.662903 
	C987.826050,705.972534 990.491028,708.507141 993.745972,709.390808 
	C994.485718,709.591614 995.112549,710.208313 995.777527,710.624878 
	C993.279602,717.695374 982.422546,720.569580 973.158081,717.139404 
	C966.422424,714.645630 962.088867,707.510620 963.014038,699.914001 
	C963.989502,691.904114 970.869629,685.530640 979.422668,685.042236 
	C986.901245,684.615173 994.523560,688.715942 996.439880,696.926147 
M980.434082,696.999939 
	C981.901672,696.897034 983.369263,696.794067 985.351135,696.655090 
	C981.252075,691.537415 977.864380,691.712158 975.204041,696.732849 
	C976.760681,696.828003 978.167542,696.914001 980.434082,696.999939 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M772.000000,704.192627 
	C772.000000,703.388794 771.981567,703.054565 772.002808,702.722778 
	C772.255371,698.787354 771.548340,694.653442 767.086182,694.401001 
	C763.245605,694.183716 762.112549,697.842224 761.910339,701.519470 
	C761.640015,706.438232 760.999695,711.336731 760.480347,716.629883 
	C757.305969,716.629883 753.935608,716.629883 750.278564,716.629883 
	C750.278564,706.577637 750.278564,696.659973 750.278564,686.753967 
	C757.574890,684.946167 760.291626,685.564697 761.685364,689.434387 
	C766.762695,685.239197 772.364624,683.112549 778.358093,686.963684 
	C780.417847,688.287170 782.620850,691.084717 782.784851,693.351685 
	C783.334717,700.952271 782.993652,708.617310 782.993652,716.627014 
	C779.309021,716.627014 775.939575,716.627014 772.000000,716.627014 
	C772.000000,712.565002 772.000000,708.614136 772.000000,704.192627 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M893.969299,557.338501 
	C893.991516,551.458679 893.991516,546.014343 893.991516,540.290283 
	C897.728760,540.290283 900.981018,540.290283 904.767944,540.290283 
	C904.767944,547.673218 905.486267,555.159546 904.567200,562.439148 
	C903.636230,569.812744 894.285889,573.813049 884.895874,571.870056 
	C876.416626,570.115479 873.022034,565.896484 873.002197,557.057983 
	C872.989929,551.601135 873.000000,546.144348 873.000000,540.346191 
	C876.740234,540.346191 880.104919,540.346191 884.000000,540.346191 
	C884.000000,545.136169 883.995605,549.746521 884.003113,554.356873 
	C884.005005,555.519226 883.606384,556.982300 884.155579,557.783875 
	C885.455261,559.680725 887.213623,561.263306 888.786621,562.972900 
	C890.506775,561.239929 892.226929,559.506897 893.969299,557.338501 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M462.585388,578.173889 
	C469.599976,575.992798 476.309509,575.728394 481.305725,581.114380 
	C485.709045,585.861267 487.475830,591.816528 484.811096,598.442139 
	C481.590393,606.450134 472.669128,610.547791 464.263031,607.662170 
	C456.448669,604.979614 452.180573,596.242065 454.462830,588.006470 
	C455.684052,583.599670 458.176300,580.394104 462.585388,578.173889 
M476.546600,591.069580 
	C475.689789,589.869324 475.054230,587.971924 473.929779,587.615173 
	C471.644196,586.890015 468.729370,586.157532 466.763153,587.012695 
	C465.025421,587.768433 463.127808,590.775879 463.295227,592.606812 
	C463.489319,594.729614 465.428772,597.509216 467.373871,598.490112 
	C471.788879,600.716553 475.381165,597.889465 476.546600,591.069580 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M245.646545,615.906677 
	C252.435593,606.905640 262.922516,607.575134 269.236938,612.058289 
	C274.493591,615.790466 276.708069,625.129272 273.905884,631.305603 
	C270.692474,638.388184 262.321594,641.877930 255.598358,640.428955 
	C245.486343,638.249695 239.515717,625.601318 245.646545,615.906677 
M265.646576,622.246948 
	C264.839722,621.406616 264.152039,620.043945 263.202850,619.827454 
	C260.731445,619.263672 257.652985,618.211670 255.719620,619.172119 
	C252.771072,620.636841 250.473068,623.979919 253.294983,627.330505 
	C254.912888,629.251587 257.876404,631.237793 260.156128,631.150757 
	C264.750153,630.975403 266.232910,627.312683 265.646576,622.246948 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M672.326904,649.123047 
	C665.684998,646.285278 663.335693,640.839966 663.137634,634.710022 
	C662.935181,628.444275 665.802124,623.340759 671.569519,620.174866 
	C675.892761,617.801758 685.474060,618.703125 689.454529,621.981812 
	C694.496399,626.134766 696.429749,633.959351 694.010132,640.419250 
	C691.658264,646.698242 686.859192,650.046753 680.008789,649.964966 
	C677.572937,649.935913 675.141968,649.504578 672.326904,649.123047 
M672.883911,636.560486 
	C674.795288,640.697937 677.861511,642.127869 682.149841,640.361694 
	C685.656433,638.917542 686.627258,635.986206 685.728699,632.647949 
	C684.708496,628.857544 681.608643,627.366394 678.022888,628.107483 
	C674.081848,628.921753 672.013306,631.687622 672.883911,636.560486 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M817.146790,635.041870 
	C820.687744,636.699402 823.858704,638.117493 827.145935,633.940918 
	C824.331665,632.936096 821.796326,631.978638 819.226868,631.123901 
	C813.219238,629.125427 810.491882,625.795532 811.011658,620.995728 
	C811.513611,616.360291 816.434082,611.981506 821.885193,612.097961 
	C826.543274,612.197388 831.183533,613.130737 836.550537,613.782593 
	C835.664612,617.434570 835.033203,620.037415 834.381592,622.723511 
	C830.204651,621.639893 826.548462,620.691345 822.892273,619.742737 
	C822.642456,620.513062 822.392578,621.283325 822.142761,622.053589 
	C823.729736,622.727539 825.264587,623.591492 826.912537,624.042969 
	C834.426758,626.100891 837.012512,628.742554 837.000000,634.599243 
	C836.987854,640.294861 834.442871,644.391846 828.967224,644.685852 
	C823.336426,644.988159 817.591187,643.699402 811.955322,642.753479 
	C811.152283,642.618774 810.060852,640.419739 810.189880,639.301453 
	C810.460144,636.958374 811.454346,634.698914 812.174866,632.327576 
	C813.927185,633.320618 815.357056,634.130859 817.146790,635.041870 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M944.976318,700.556763 
	C946.831116,695.619385 948.632935,691.036438 950.527954,686.216309 
	C954.343262,686.216309 958.308289,686.216309 962.900269,686.216309 
	C960.352661,691.988831 957.909180,697.522034 955.468811,703.056519 
	C954.275391,705.763123 952.965881,708.426392 951.925232,711.190735 
	C950.384216,715.284241 948.215759,717.842651 943.179932,717.133240 
	C940.668579,716.779480 939.008423,716.512512 937.900391,713.888794 
	C934.074219,704.828064 929.981750,695.879761 925.601746,686.012512 
	C929.522400,686.012512 932.827881,685.612915 935.954773,686.186584 
	C937.445190,686.460083 939.058960,688.361267 939.808044,689.915039 
	C941.578064,693.586731 942.862732,697.492371 944.355835,701.298584 
	C944.373413,701.343445 944.599792,701.372620 944.649170,701.325562 
	C944.766052,701.214233 944.834961,701.052551 944.976318,700.556763 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M729.117065,715.650146 
	C729.036499,701.374817 729.036499,687.563171 729.036499,673.376831 
	C733.352478,673.376831 737.390930,673.376831 741.718079,673.376831 
	C741.718079,687.757263 741.718079,702.011719 741.718079,717.253662 
	C737.470459,716.867004 733.334045,716.490417 729.117065,715.650146 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M912.003174,527.233521 
	C912.839478,525.843140 913.593872,524.247986 914.522034,524.139465 
	C917.446594,523.797546 920.436401,524.013428 923.694031,524.013428 
	C923.694031,539.704712 923.694031,554.959045 923.694031,570.607605 
	C920.012756,570.607605 916.307373,570.607605 912.000793,570.607605 
	C912.000793,556.259460 912.000793,541.984253 912.003174,527.233521 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M860.279480,548.058228 
	C855.213867,550.612976 853.794373,553.913696 855.583130,558.098816 
	C857.359375,562.254761 859.891602,562.739563 867.980225,560.177124 
	C867.980225,562.942078 868.190308,565.559692 867.876709,568.113037 
	C867.751465,569.132935 866.657837,570.501953 865.685181,570.872498 
	C860.105835,572.998169 854.480896,572.690552 849.728333,569.043335 
	C844.732422,565.209473 842.555481,559.759705 843.808899,553.427063 
	C846.419495,540.237671 855.113831,535.645203 867.820801,540.735229 
	C867.820801,543.793640 867.820801,546.926147 867.820801,550.224243 
	C865.413208,549.481506 863.043884,548.750549 860.279480,548.058228 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M906.905029,693.621582 
	C903.940186,695.001099 902.625549,696.612061 902.888367,700.024109 
	C903.306396,705.450439 903.000000,710.932617 903.000000,716.691528 
	C899.304199,716.691528 896.047302,716.691528 892.273682,716.691528 
	C891.156616,709.156250 893.762695,700.546997 887.292114,692.968445 
	C887.735107,690.927185 884.477295,686.236328 890.977478,685.362671 
	C890.977478,682.741516 890.977478,680.268738 890.977478,677.398193 
	C894.941956,677.398193 898.651733,677.398193 902.774719,677.398193 
	C902.902954,680.000427 903.030823,682.595703 903.170410,685.429138 
	C908.844971,685.839233 906.640869,690.097961 906.905029,693.621582 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M816.969299,717.000000 
	C814.874573,717.000000 813.249146,717.000000 811.193237,717.000000 
	C811.193237,709.671509 811.193237,702.764709 811.193237,695.619690 
	C809.440430,695.253174 808.053528,694.963135 806.333130,694.603455 
	C806.333130,692.088989 806.333130,689.499207 806.333130,686.556213 
	C807.627075,686.250549 809.142395,685.892639 810.810608,685.498596 
	C810.947571,682.760803 811.077515,680.164856 811.221191,677.293030 
	C814.706787,677.293030 817.958496,677.293030 821.684692,677.293030 
	C821.684692,679.837708 821.684692,682.408325 821.684692,685.245850 
	C823.564148,685.719971 825.065063,686.098572 826.767944,686.528076 
	C826.767944,689.154236 826.767944,691.743469 826.767944,694.649963 
	C825.271729,694.871521 823.872681,695.078674 822.093262,695.342163 
	C822.093262,702.418213 822.093262,709.335938 822.093262,716.647217 
	C820.289001,716.783997 818.863831,716.891968 816.969299,717.000000 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M984.000000,560.977661 
	C984.000000,564.426941 984.000000,567.384277 984.000000,570.668335 
	C980.359314,570.668335 977.108337,570.668335 973.255737,570.668335 
	C973.255737,563.454346 973.255737,556.084656 973.255737,548.488281 
	C971.325195,548.152649 969.931213,547.910278 968.280029,547.623169 
	C968.280029,545.295715 968.280029,543.040039 968.280029,540.460510 
	C969.613953,540.195557 971.008301,539.918640 972.799011,539.562988 
	C972.912415,537.024170 973.028687,534.421753 973.163330,531.409790 
	C976.580139,531.409790 979.948425,531.409790 983.769897,531.409790 
	C983.894104,533.947876 984.020691,536.535400 984.161926,539.421875 
	C985.654602,539.769531 987.170654,540.122681 988.817993,540.506348 
	C988.817993,542.805481 988.817993,544.933289 988.817993,547.394653 
	C987.392090,547.736816 985.883362,548.098816 984.000000,548.550781 
	C984.000000,552.573181 984.000000,556.529419 984.000000,560.977661 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M842.000000,699.009033 
	C842.000000,705.134216 842.000000,710.760986 842.000000,716.689331 
	C837.974854,716.689331 834.385559,716.689331 830.394409,716.689331 
	C830.394409,706.698914 830.394409,696.661621 830.394409,686.310242 
	C834.027222,686.310242 837.617920,686.310242 842.000000,686.310242 
	C842.000000,690.341309 842.000000,694.426025 842.000000,699.009033 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M917.938232,686.000000 
	C919.718079,686.061707 921.024597,686.123352 922.660278,686.200562 
	C922.660278,696.299744 922.660278,706.222412 922.660278,716.574036 
	C919.080933,716.574036 915.374817,716.574036 911.329834,716.574036 
	C911.329834,706.680725 911.329834,696.758240 911.329834,686.000000 
	C913.308899,686.000000 915.386902,686.000000 917.938232,686.000000 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M798.765625,686.022827 
	C799.816772,686.851501 800.898621,687.656921 800.909302,688.476257 
	C801.029480,697.731079 800.985474,706.988037 800.985474,716.620605 
	C797.335144,716.620605 793.978149,716.620605 790.306396,716.620605 
	C790.306396,706.553589 790.306396,696.556152 790.306396,686.006165 
	C793.099731,686.006165 795.699280,686.006165 798.765625,686.022827 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M826.000000,554.005371 
	C826.000000,549.211243 826.000000,544.915039 826.000000,540.314514 
	C829.684692,540.314514 832.939941,540.314514 836.602478,540.314514 
	C836.602478,550.291138 836.602478,560.329651 836.602478,570.686035 
	C833.314270,570.686035 830.055969,570.686035 826.000000,570.686035 
	C826.000000,565.347046 826.000000,559.925232 826.000000,554.005371 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M853.000000,629.994629 
	C853.000000,634.788757 853.000000,639.084961 853.000000,643.685486 
	C849.315308,643.685486 846.060059,643.685486 842.397522,643.685486 
	C842.397522,633.708862 842.397522,623.670349 842.397522,613.313965 
	C845.685730,613.313965 848.944031,613.313965 853.000000,613.313965 
	C853.000000,618.652954 853.000000,624.074768 853.000000,629.994629 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M835.986572,532.897766 
	C835.170532,536.978149 832.423340,537.899780 829.622009,536.523438 
	C828.322815,535.885132 827.105164,532.584717 827.673035,531.291077 
	C828.342957,529.764771 830.956909,528.414856 832.783386,528.340210 
	C833.795227,528.298889 834.919861,531.018005 835.986572,532.897766 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M847.209473,601.066040 
	C849.064148,602.620056 851.209351,604.030823 851.707520,605.884277 
	C851.966431,606.847656 849.485107,609.418762 847.929504,609.736755 
	C846.563599,610.015991 844.762390,608.166077 843.153320,607.256470 
	C844.374512,605.206482 845.595703,603.156494 847.209473,601.066040 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M792.640442,675.302246 
	C795.003906,675.447998 797.519165,675.186096 798.813599,676.319092 
	C799.746094,677.135193 799.708618,680.228149 798.881287,681.526611 
	C798.183960,682.621033 795.552856,683.248962 794.100220,682.864624 
	C792.840332,682.531250 791.625061,680.617126 791.165588,679.157471 
	C790.848816,678.151123 791.854736,676.728394 792.640442,675.302246 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M915.255859,675.075562 
	C920.614197,675.169006 922.832336,677.127441 921.208008,680.213501 
	C920.408447,681.732605 918.617798,683.107788 916.977539,683.656189 
	C916.080627,683.955994 913.532654,682.416077 913.518250,681.671265 
	C913.476196,679.498718 914.334412,677.308655 915.255859,675.075562 
z"/>
						<path fill={state ? "#fff" : isHomePage ? '#fff' : "#94a090"} opacity="1" stroke="none"
							d="
M832.315979,676.468750 
	C835.459229,674.610718 838.729736,673.491272 840.014221,677.638000 
	C840.465271,679.094421 838.827332,682.124146 837.297363,683.076355 
	C834.048340,685.098389 831.951416,682.462830 832.315979,676.468750 
z"/>
						<path fill='#FFFFFF' opacity="0" stroke="none"
							d="
M204.067688,662.916626 
	C216.397095,668.790222 228.786896,674.542297 241.013870,680.621887 
	C244.113129,682.163025 245.902069,681.760620 248.258347,679.352783 
	C259.894043,667.462341 271.718872,655.756958 282.977478,644.486450 
	C304.618286,666.128296 326.087067,687.598206 347.303131,708.815308 
	C347.370941,708.809143 346.327972,708.986816 345.284851,708.987732 
	C326.641937,709.004578 307.998108,709.086914 289.357422,708.883118 
	C287.178162,708.859253 284.520081,707.756226 282.932495,706.255737 
	C276.044495,699.745850 269.471130,692.903076 262.148468,685.543213 
	C259.072266,688.899414 256.266632,692.161438 253.249283,695.214294 
	C244.533264,704.032959 244.499863,703.999817 235.565643,695.065613 
	C230.269073,689.769104 225.023895,684.419922 219.660934,679.191467 
	C214.447418,674.108643 209.113129,669.149719 203.822067,663.687744 
	C203.812271,663.239014 204.067688,662.916626 204.067688,662.916626 
z"/>
						<path fill="#FFFFFF" opacity="1" stroke="none"
							d="
M203.675842,663.392883 
	C203.204224,663.141846 202.869034,662.736877 202.533844,662.331970 
	C202.956238,662.361389 203.378632,662.390808 203.934357,662.668457 
	C204.067688,662.916626 203.812271,663.239014 203.675842,663.392883 
z"/>
						<path fill='#FFFFFF' opacity="0" stroke="none"
							d="
M408.000397,705.816162 
	C408.000000,690.684326 407.928406,676.030518 408.086029,661.379272 
	C408.106903,659.437805 408.743073,656.969910 410.026581,655.657532 
	C422.945312,642.447510 436.058746,629.427795 448.774536,616.697876 
	C464.699951,632.634033 480.513153,648.457947 496.518707,664.474304 
	C495.002380,665.808350 492.933075,667.628967 490.448212,669.815186 
	C485.171753,665.342773 479.415527,660.463745 473.328461,655.304321 
	C465.978699,662.620667 458.418396,670.014099 451.076935,677.618774 
	C450.135071,678.594421 450.060699,680.665771 450.044647,682.234192 
	C449.952759,691.214783 450.000000,700.196777 450.000000,710.000000 
	C447.353790,710.000000 444.941437,710.000061 442.529053,710.000000 
	C432.371887,709.999939 422.213745,709.922913 412.058136,710.049316 
	C409.243774,710.084290 407.470032,709.591919 408.000397,705.816162 
z"/>
						<path fill='#FFFFFF' opacity="0" stroke="none"
							d="
M630.000000,600.000122 
	C630.000000,608.494934 629.785156,616.499268 630.133301,624.478943 
	C630.235352,626.818481 631.370361,629.642761 632.994934,631.301941 
	C645.459106,644.031128 658.160950,656.527649 671.102295,669.408264 
	C660.102234,680.404663 648.956726,690.869019 638.661072,702.111633 
	C633.135376,708.145508 627.430664,710.519104 619.229248,710.200867 
	C602.124207,709.537231 584.975525,710.000000 566.940125,710.000000 
	C568.473206,708.278259 569.448059,707.068481 570.539551,705.974976 
	C584.659424,691.828796 598.870361,677.771912 612.832886,663.472412 
	C614.555359,661.708374 615.834351,658.683777 615.864380,656.225525 
	C616.106506,636.407776 616.098328,616.584900 615.914368,596.765930 
	C615.878967,592.954468 616.878784,590.196228 619.726379,587.659485 
	C623.017029,584.728088 625.891724,581.329773 630.000000,577.032837 
	C630.000000,585.659302 630.000000,592.579773 630.000000,600.000122 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M748.312439,632.807495 
	C746.125244,632.930054 744.341125,632.930054 742.280273,632.930054 
	C742.280273,625.190308 742.280273,617.825073 742.280273,610.486938 
	C749.272400,609.641052 754.655518,612.686401 755.910767,618.038757 
	C757.628418,625.362610 755.195801,630.314148 748.312439,632.807495 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M753.747681,542.481323 
	C754.765015,546.115295 755.622437,549.393494 756.519409,552.822876 
	C753.401611,552.822876 750.471863,552.822876 747.046326,552.822876 
	C748.516357,547.820312 749.899963,543.111877 751.575867,537.408813 
	C752.499878,539.575195 753.043823,540.850342 753.747681,542.481323 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M881.024597,634.388672 
	C877.992188,634.097229 874.500916,634.382141 872.706909,632.773804 
	C869.828552,630.193420 870.078186,625.911011 873.211365,623.668884 
	C875.122253,622.301392 879.114563,622.009949 881.169617,623.109070 
	C884.199768,624.729919 885.076050,628.284302 883.049011,631.739929 
	C882.556519,632.579529 881.905518,633.326050 881.024597,634.388672 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M1048.588257,561.950562 
	C1042.338745,561.755920 1038.977417,558.271790 1040.176270,553.686340 
	C1041.088623,550.196655 1044.974365,547.519409 1047.832520,548.411011 
	C1052.154541,549.759338 1054.289062,553.483826 1052.624878,557.534729 
	C1051.936768,559.209839 1050.235229,560.468506 1048.588257,561.950562 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M860.000122,702.002197 
	C859.928955,697.099426 862.148743,694.712769 866.619751,695.063354 
	C870.592224,695.374878 871.909973,697.981140 872.087708,701.841125 
	C872.289795,706.228882 870.111572,708.671997 866.279907,708.817261 
	C862.535950,708.959229 859.664001,706.933655 860.000122,702.002197 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M953.068665,558.037354 
	C951.277710,562.673096 947.673096,562.597473 944.338074,561.541077 
	C941.134644,560.526428 940.061218,555.166077 941.828247,551.558899 
	C943.369324,548.412842 945.850525,547.112915 949.065674,548.628845 
	C952.811096,550.394714 955.300171,553.054993 953.068665,558.037354 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M1005.307739,547.208679 
	C1009.637634,545.621033 1009.637634,545.621033 1013.125916,550.498596 
	C1009.704773,550.498596 1006.613220,550.498596 1002.872498,550.498596 
	C1003.670166,549.377441 1004.348877,548.423523 1005.307739,547.208679 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M793.268677,623.999268 
	C790.584473,624.000000 788.294067,624.000000 786.003662,624.000000 
	C785.738953,623.572266 785.474243,623.144531 785.209473,622.716797 
	C786.884888,621.620911 788.679932,619.511047 790.204651,619.691040 
	C792.024475,619.905884 793.612854,622.081726 795.301453,623.408142 
	C794.755188,623.604980 794.208862,623.801758 793.268677,623.999268 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M980.004211,697.000000 
	C978.167542,696.914001 976.760681,696.828003 975.204041,696.732849 
	C977.864380,691.712158 981.252075,691.537415 985.351135,696.655090 
	C983.369263,696.794067 981.901672,696.897034 980.004211,697.000000 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M476.714783,591.411133 
	C475.381165,597.889465 471.788879,600.716553 467.373871,598.490112 
	C465.428772,597.509216 463.489319,594.729614 463.295227,592.606812 
	C463.127808,590.775879 465.025421,587.768433 466.763153,587.012695 
	C468.729370,586.157532 471.644196,586.890015 473.929779,587.615173 
	C475.054230,587.971924 475.689789,589.869324 476.714783,591.411133 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M265.782043,622.614380 
	C266.232910,627.312683 264.750153,630.975403 260.156128,631.150757 
	C257.876404,631.237793 254.912888,629.251587 253.294983,627.330505 
	C250.473068,623.979919 252.771072,620.636841 255.719620,619.172119 
	C257.652985,618.211670 260.731445,619.263672 263.202850,619.827454 
	C264.152039,620.043945 264.839722,621.406616 265.782043,622.614380 
z"/>
						<path fill="#FFFFFF" opacity="0.000000" stroke="none"
							d="
M672.669678,636.246582 
	C672.013306,631.687622 674.081848,628.921753 678.022888,628.107483 
	C681.608643,627.366394 684.708496,628.857544 685.728699,632.647949 
	C686.627258,635.986206 685.656433,638.917542 682.149841,640.361694 
	C677.861511,642.127869 674.795288,640.697937 672.669678,636.246582 
z"/>
					</svg>

				</div>

				<div ref={navRef} className='nav-menu' onClick={!burger ? handleMenu : undefined}>
					<ul>
						<li><Link to='/About' className='menu-link' style={{ color: screenWidth <= 530 ? 'white' : isHomePage ? 'white' : '#94a090' }}>About</Link></li>
						<li><Link to='/Services' className='menu-link' style={{ color: screenWidth <= 530 ? 'white' : isHomePage ? 'white' : '#94a090' }}>Projects</Link></li>
						<li><Link to='/Media' className='menu-link' style={{ color: screenWidth <= 530 ? 'white' : isHomePage ? 'white' : '#94a090' }}>Media</Link></li>
						<li><Link to='/Contact' className='menu-link' style={{ color: screenWidth <= 530 ? 'white' : isHomePage ? 'white' : '#94a090' }}>Contact</Link></li>
					</ul>
				</div>
				{screenWidth > 530 ? <div className='social-media-header' style={{ color: isHomePage ? 'white' : '#94a090' }}>
					<Link className='social-media-icons' target="_blank" to='https://instagram.com/adinitiative.in?igshid=OGQ5ZDc2ODk2ZA==' rel="noopener noreferrer"><i class="fab fa-brands fa-instagram"></i></Link>

					{/* <Link className='social-media-icons' target="_blank" to='https://wa.me/+917506030065' ><i class="fab fa-brands fa-whatsapp" rel="noopener noreferrer"></i></Link> */}
				</div> : <></>}
				<div className="burger-btn" onClick={handleMenu} style={{ color: isHomePage ? 'white' : state ? 'white' : '#94a090', fontSize: "20px" }}>
					<Link className='social-media-icons' target="_blank" to='https://instagram.com/adinitiative.in?igshid=OGQ5ZDc2ODk2ZA==' rel="noopener noreferrer"><i class="fab fa-brands fa-instagram"></i></Link>
					{burger ? <FaBars /> : <FaTimes />}
				</div>
			</nav>
		</div>
	)
}

export default Navbar